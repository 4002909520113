import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Masonry from "@mui/lab/Masonry";
import { Link } from "react-router-dom";
import { useGallery } from "../../srcApi/ContexApit";
// import "@fancyapps/fancybox/dist/jquery.fancybox.css";
// import Fancybox from "@fancyapps/fancybox";

export const Gallery = () => {
  const galleryData = useGallery();
  useEffect(() => {
    // Initialize Fancybox when the component mounts
    if (window.$.fancybox) {
      window.$.fancybox.defaults.animationEffect = "fade"; // Customize options if needed
      window.$("[data-fancybox]").fancybox({
        // Your custom options here
      });
    }
  }, []);
  if (!galleryData) {
    return null; // or display a loading message
  }

  return (
    <>
      <section className="section-padding gallery-section">
        <Container>
          <div className="d-flex align-items-center">
            <div className="cl-header">
              <h2 className="title">Gallery</h2>
              <p>
                I’m proud to showcase some of my finest pieces of artwork. Here
                are some of my original watercolors – and acrylics – and high
                quality Giclée prints.
              </p>
            </div>
            <Link to="/gallery" className="cl-button ms-auto">
              view All
              <i className="icon fa-solid fa-arrow-right"></i>
            </Link>
          </div>
          <Masonry columns={{ xs: 2, sm: 3 }} spacing={{ xs: 1, sm: 2, md: 3 }}>
            {galleryData?.slice(0, 9).map((image, index) => (
              <div key={index} className="img-wrap">
                <Link to={image.image} data-fancybox="gallery">
                  <img
                    src={image.image}
                    style={{ width: "100%", display: "block" }}
                    alt={`Gallery Image ${index}`}
                  />
                </Link>
              </div>
            ))}
          </Masonry>
        </Container>
      </section>
    </>
  );
};
