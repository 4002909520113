import React from "react";
import "./InnerEvent.css";
import { Header } from "../../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { BiCalendar } from "react-icons/bi";
import { useEvent } from "../../../srcApi/ContexApit";
import parse from "html-react-parser";
import { Footer } from "../../Footer/Footer";

export const EventDetail = () => {
  const getEventData = useEvent();
  const imagepath = "https://artnbgurung.com/uploads/image/";
  return (
    <>
      <div className="inner-header">
        <Header />
      </div>
      <section className="section-padding eventdetail-section">
        <Container>
          {getEventData?.map((event, index) => (
            <div className="event-list">
              <Row>
                <Col md={4}>
                  <div className="event-img">
                    <img src="images/event/eventlist.png" alt="eventlist" />
                  </div>
                </Col>
                <Col md={8}>
                  <div className="event-content">
                    <div className="event-date">
                      <BiCalendar className="icon" />
                      <span>{event.published_at}</span>
                    </div>
                    <div className="cl-header">
                      <h2 className="title">{event.title}/</h2>
                    </div>
                    <div className="content">
                      <p>{parse(event.description)}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
        </Container>
      </section>
      <Footer />
    </>
  );
};
