import React, { useEffect, useState } from "react";
import "./ImgSlider.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container } from "react-bootstrap";
import { useGallery } from "../../srcApi/ContexApit";
import { Loader } from "../Loarder";
export const Slider = () => {
  const [loading, setLoading] = useState(true);

  const galleryData = useGallery();
  const imgSlider = {
    loop: true,
    center: true,
    items: 4,
    margin: 30,
    autoplay: true,
    dots: false,
    center: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    console.log("image slider is here:", galleryData);
  }, []);

  return (
    <>
      <div>
        <Container>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader className="text-center" />
            </div>
          ) : (
            <OwlCarousel
              className="owl-carousel owl-theme img-slider"
              {...imgSlider}
            >
              {galleryData?.map((imglink, index) => (
                <div key={index} className="item">
                  <div className="image-holder">
                    <img src={imglink.image} alt={`Gallery Images ${index}`} />
                  </div>
                </div>
              ))}
            </OwlCarousel>
          )}
        </Container>
      </div>
    </>
  );
};
