import React from "react";
import "./ForSale.css";
import { Header } from "../../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useProduct } from "../../../srcApi/ContexApit";
import parse from "html-react-parser";
import { Footer } from "../../Footer/Footer";

export const SaleDetail = () => {
  const productData = useProduct();
  const imagepath = "https://artnbgurung.com/uploads/image/";

  return (
    <>
      <div className="inner-header">
        <Header />
      </div>
      <section className="section-padding saledetail-section">
        <Container>
          {productData.map((product) => (
            <Row>
              <Col md={5} key={product.id}>
                <div className="forsale-wrap">
                  <div className="img-wrap">
                    <img
                      src={imagepath + product.images[0].image}
                      alt={product.title}
                    />
                  </div>
                </div>
              </Col>

              <Col md={7} key={product.id}>
                <div className="content-wrap">
                  <div className="cl-header">
                    <h5 className="title">{product.title}</h5>
                  </div>
                  <span className="tag">${product.price}</span>
                  <ul className="desc-list">
                    <li>
                      <strong>Size: </strong>
                      {parse(product.specifications)}
                    </li>
                    <li>
                      <strong> Medium: </strong>
                      {parse(product.description)}
                    </li>
                  </ul>
                  <p> {parse(product.description)}</p>
                  <Link to="/" className="cl-button">
                    Buy Now
                    <i className="icon fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </Col>
            </Row>
          ))}
        </Container>
      </section>
      <section className="section-padding sale-section">
        <div className="sale-container">
          <Container>
            <div className="d-flex header-wrapper">
              <div className="cl-header ">
                <h2 className="title">Simmilar product</h2>
              </div>
              <Link to="/forsale" className="cl-button ms-auto">
                View All <i className="icon fa-solid fa-arrow-right"></i>
              </Link>
            </div>

            <Row className="justify-content-center">
              {productData.map((product) => (
                <Col sm={6} key={product.id}>
                  <div className="forsale-wrap">
                    <Link className="d-block" to="/forsale-detail">
                      <div className="img-wrap">
                        <img
                          src={imagepath + product.images[0].image}
                          alt={product.title}
                        />
                      </div>
                      <div className="content">
                        <h5 className="title">{product.title}</h5>
                        <span className="tag">${product.price}</span>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))}
              {/* <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage1.png"
                        alt="saleImage1"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">The Monkey Temple</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage2.png"
                        alt="saleImage2"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">ACRYLIC ‘MOUNT DHAULAGIRI’</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage3.png"
                        alt="saleImage3"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">The Monkey Temple</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage4.png"
                        alt="saleImage4"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">ACRYLIC ‘MOUNT DHAULAGIRI’</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage5.png"
                        alt="saleImage5"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">The Monkey Temple</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img src="images/forsale/saleImage.png" alt="saleImage" />
                    </div>
                    <div className="content">
                      <h5 className="title">ACRYLIC ‘MOUNT DHAULAGIRI’</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage1.png"
                        alt="saleImage1"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">The Monkey Temple</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
      </section>
      <Footer />
    </>
  );
};
