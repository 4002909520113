import React, { useEffect, useState } from "react";
import "./ForSale.css";
import { Header } from "../../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useProduct } from "../../../srcApi/ContexApit";
import parse from "html-react-parser";
import { Loader } from "../../Loarder";

export const ForSale = () => {
  const productData = useProduct();
  const [loading, setloading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 2000);
  }, []);
  const imagepath = "https://artnbgurung.com/uploads/image/";
  return (
    <>
      {/* {loading ? (
        <div className="d-flex jusctify-content-center">
          <Loader />
        </div>
      ) : ( */}
      <div>
        <div className="inner-header">
          <Header />
        </div>
        <section className="section-padding sale-section">
          <div className="sale-container">
            {productData?.length > 0 ? (
              productData?.map((product, index) => (
                <Container key={index}>
                  <div className="cl-header w-50">
                    <h2 className="title">{product.title}</h2>
                    <p>{parse(product.description)} </p>
                  </div>
                  <Row>
                    <Col sm={6}>
                      <div className="forsale-wrap">
                        <Link className="d-block" to="/forsale-detail">
                          <div className="img-wrap">
                            <img
                              src={imagepath + product.images[0].image}
                              alt="saleImage"
                            />
                          </div>
                          <div className="content">
                            {/* <h5 className="title">ACRYLIC ‘MOUNT DHAULAGIRI’</h5> */}
                            <h5 className="title">{product.title}</h5>
                            <span className="tag">{product.price}</span>
                          </div>
                        </Link>
                      </div>
                    </Col>

                    {/* <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage1.png"
                        alt="saleImage1"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">The Monkey Temple</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage2.png"
                        alt="saleImage2"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">ACRYLIC ‘MOUNT DHAULAGIRI’</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage3.png"
                        alt="saleImage3"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">The Monkey Temple</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage4.png"
                        alt="saleImage4"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">ACRYLIC ‘MOUNT DHAULAGIRI’</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage5.png"
                        alt="saleImage5"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">The Monkey Temple</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img src="images/forsale/saleImage.png" alt="saleImage" />
                    </div>
                    <div className="content">
                      <h5 className="title">ACRYLIC ‘MOUNT DHAULAGIRI’</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col>
              <Col sm={6}>
                <div className="forsale-wrap">
                  <Link className="d-block" to="/forsale-detail">
                    <div className="img-wrap">
                      <img
                        src="images/forsale/saleImage1.png"
                        alt="saleImage1"
                      />
                    </div>
                    <div className="content">
                      <h5 className="title">The Monkey Temple</h5>
                      <span className="tag">$399</span>
                    </div>
                  </Link>
                </div>
              </Col> */}
                  </Row>
                </Container>
              ))
            ) : (
              <p>No products available</p>
            )}
          </div>
        </section>
      </div>
      {/* )} */}
    </>
  );
};
