import React, { useEffect, useState } from "react";
import "./Banner.css";
import { Header } from "../Header/Header";
// import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { BiLogoFacebookCircle, BiLogoInstagram } from "react-icons/bi";
import { getBannerData, getFooter } from "../../srcApi/Api";
import parse from "html-react-parser";

export const Banner = () => {
  const [bannerData, setBannerData] = useState();
  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const updateBannerData = await getBannerData();
        console.log("hi", updateBannerData);
        setBannerData(updateBannerData.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchBannerData();
  }, []);
  const [footerData, setFooterData] = useState();
  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const updatefooter = await getFooter();
        console.log("footer data sucess:", updatefooter);
        const filteredData = updatefooter.data.filter(
          (item) => item.slug === "facebook" || item.slug === "instagram"
        );

        setFooterData(filteredData);
      } catch (err) {
        console.log("footer data failed:", err);
      }
    };
    fetchFooterData();
  }, []);
  const imagepath = "https://artnbgurung.com/uploads/image/";
  return (
    <>
      <div className="cl-banner">
        <Header />
        <section className="banner-container">
          {bannerData && (
            <Container>
              <div className="banner-wrapper d-flex align-items-center">
                <div className="banner-text">
                  <h1 className="title">
                    {/* {bannerData.blocks["main-block"].title} */}
                    {parse(bannerData.blocks.about.content)}
                  </h1>
                  <Link to="/gallery" className="cl-button">
                    View Gallery<i className="icon fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
                <div className="banner-img">
                  <img
                    src={imagepath + bannerData.blocks.about.image}
                    alt="NB-Gurung"
                  />
                </div>
                <ul className="social-icon">
                  {footerData?.map((item) => (
                    <li key={item.id}>
                      <Link to={item.value} target="_blank">
                        {item.slug === "facebook" && (
                          <BiLogoFacebookCircle className="icon fb" />
                        )}
                        {item.slug === "instagram" && (
                          <BiLogoInstagram className="icon tw" />
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Container>
          )}
        </section>
      </div>
    </>
  );
};
