import React from "react";
import { ColorRing } from "react-loader-spinner";

export const Loader = () => {
  return (
    <>
      <ColorRing
        visible={true}
        height="35"
        width="35"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
        // colors={["#6cb3ab", "#2b384c", "#699bbf", "#132137", "#606060"]}
      />
    </>
  );
};
