import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/Components/Style/Main.css";
import { Home } from "./Components/FrontPage/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { InnerAbout } from "./Components/InnerPages/innerAbout/InnerAbout";
import { InnerGallery } from "./Components/InnerPages/innerGallery/InnerGallery";
import { InnerEvent } from "./Components/InnerPages/innerEvent/InnerEvent";
import { EventDetail } from "./Components/InnerPages/innerEvent/EventDetail";
import { ForSale } from "./Components/InnerPages/forsale/ForSale";
import { SaleDetail } from "./Components/InnerPages/forsale/SaleDetail";
import Fancybox from "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.css";
import {
  EventProvider,
  GalleryProvider,
  ProductProvider,
} from "./srcApi/ContexApit";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <InnerAbout />,
    },
    {
      path: "/gallery",
      element: <InnerGallery />,
    },
    {
      path: "/event",
      element: <InnerEvent />,
    },
    {
      path: "/eventdetail",
      element: <EventDetail />,
    },
    {
      path: "/forsale",
      element: <ForSale />,
    },
    {
      path: "forsale-detail",
      element: <SaleDetail />,
    },
  ]);
  return (
    <div className="App">
      <EventProvider>
        <GalleryProvider>
          <ProductProvider>
            <RouterProvider router={router} />
          </ProductProvider>
        </GalleryProvider>
      </EventProvider>
    </div>
  );
}

export default App;
