import React, { useEffect, useState } from "react";
import "../../Gallery/Gallery.css";
import { Header } from "../../Header/Header";
import { Container } from "react-bootstrap";
import { Masonry } from "@mui/lab";
import { useGallery } from "../../../srcApi/ContexApit";
import { Footer } from "../../Footer/Footer";
import { Link } from "react-router-dom/dist";

export const InnerGallery = () => {
  const galleryData = useGallery();
  useEffect(() => {
    // Initialize Fancybox when the component mounts
    if (window.$.fancybox) {
      window.$.fancybox.defaults.animationEffect = "fade"; // Customize options if needed
      window.$("[data-fancybox]").fancybox({
        // Your custom options here
      });
    }
  }, []);
  if (!galleryData) {
    return null; // or display a loading message
  }

  return (
    <>
      <div className="inner-header">
        <Header />
      </div>
      <section className="gallery-section section-padding">
        <Container>
          <div className="cl-header">
            <h2 className="title">Gallery</h2>
            <p>
              I’m proud to showcase some of my finest pieces of artwork. Here
              are some of my original watercolors – and acrylics – and high
              quality Giclée prints.
            </p>
          </div>
          <div className="photo-gallery">
            <Masonry
              columns={{ xs: 2, sm: 3 }}
              spacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {galleryData?.map((image, index) => (
                <div key={index} className="img-wrap">
                  <Link to={image.image} data-fancybox="gallery">
                    <img
                      src={image.image}
                      style={{ width: "100%", display: "block" }}
                      alt={`Gallery Image ${index}`}
                    />
                  </Link>
                </div>
              ))}
            </Masonry>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
};
