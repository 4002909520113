import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
export const getBannerData = async () => {
  try {
    const response = await axiosInstance.get("/page/home");
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const getEvent = async () => {
  try {
    const response = await axiosInstance.get("/blogs");
    console.log("api fetch sucess:", response);
    return response;
  } catch (err) {
    console.log("api fetch failed:", err);
  }
};

export const getgallery = async () => {
  try {
    const response = await axiosInstance.get("/gallery");
    return response;
  } catch (err) {
    console.log("gallery not fetch:", err);
  }
};
export const getFooter = async () => {
  try {
    const response = await axiosInstance.get("/setting");
    console.log("response sucess:", response);
    return response;
  } catch (err) {
    console.log("footer data fetched failed:", err);
  }
};
export const getAbout = async () => {
  try {
    const response = await axiosInstance.get("/page/about");
    console.log("about api:", response);
    return response;
  } catch (err) {
    // console.log("about api:", err);
  }
};

export const getProduct = async () => {
  try {
    const response = await axiosInstance.get("/products");
    return response;
  } catch (err) {
    console.log("slae fetch data failed:", err);
  }
};
