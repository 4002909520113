import React, { useEffect, useState } from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { BiX } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";

export const Header = () => {
  // const [logoImage, setLogoImage] = useState(); // Initial logo image
  const location = useLocation(); // Get the current location from React Router

  // useEffect(() => {
  //   let newLogoImage = process.env.PUBLIC_URL + "/Images/logo.png";

  //   switch (location.pathname) {
  //     case "/about":
  //     case "/gallery":
  //     case "/event":
  //     case "/eventdetail":
  //     case "/forsale":
  //     case "/forsale-detail":
  //       newLogoImage = process.env.PUBLIC_URL + "/Images/logo-1.png";
  //       break;
  //     default:
  //       newLogoImage = process.env.PUBLIC_URL + "/Images/logo.png";
  //   }
  //   setLogoImage(newLogoImage);
  //   setActiveNavlink(location.pathname.replace("/", ""));
  // }, [location.pathname]);

  const [isToggled, setIsToggled] = useState(false);
  const toggleNavbar = () => {
    setIsToggled(!isToggled);
  };
  const dataLink = [
    { id: "about", label: "About" },
    { id: "gallery", label: "Gallery" },
    { id: "event", label: "Events" },
    { id: "forsale", label: "For sale" },
  ];

  // const [activeNavLink, setActiveNavlink] = useState();
  // const handleClickLink = (handleNavLinkClick) => {
  //   setActiveNavlink(handleNavLinkClick);
  // };
  const [activeNavLink, setActiveNavlink] = useState(
    location.pathname.replace("/", "")
  );

  return (
    <>
      <div className="cl-header">
        <Navbar expand="lg" className=" cl-navigation">
          <Container>
            <Navbar.Brand>
              <Link to="/" className="d-block">
                <img
                  src={
                    location.pathname === "/"
                      ? "/Images/logo.png"
                      : "/Images/logo-1.png"
                  }
                  alt="logo"
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" onClick={toggleNavbar}>
              {isToggled ? <BiX /> : ""}
            </Navbar.Toggle>
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto  my-lg-0">
                {dataLink?.map((link, i) => (
                  <Nav className="ms-auto  my-lg-0" navbarScroll>
                    <Link
                      key={i}
                      to={`/${link.id}`}
                      className={`nav-link ${
                        activeNavLink === link.id ? "active" : ""
                      }`}
                    >
                      {link.label}
                    </Link>
                  </Nav>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};
