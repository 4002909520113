import React, { createContext, useContext, useEffect, useState } from "react";
import { getEvent, getProduct, getgallery } from "./Api";

// gallery context  code start
// Create a context for the gallery data
const GalleryContext = createContext();

// Custom hook to access the gallery data
export const useGallery = () => useContext(GalleryContext);

// Gallery data provider component
export const GalleryProvider = ({ children }) => {
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const updateGalleryData = await getgallery();
        setGalleryData(updateGalleryData.data);
        console.log("gallery images fetched:", updateGalleryData);
      } catch (err) {
        console.log("images not fetched:", err);
      }
    };
    fetchGalleryData();
  }, []);
  return (
    <>
      <GalleryContext.Provider value={galleryData}>
        {children}
      </GalleryContext.Provider>
    </>
  );
};
// gallery context  code end

// event context  code start
const EventContext = createContext();
export const useEvent = () => useContext(EventContext);

export const EventProvider = ({ children }) => {
  const [getEventData, setGetEventData] = useState();
  useEffect(() => {
    const fetchEventdata = async () => {
      try {
        const updateEvent = await getEvent();
        console.log("event fetch sucess:", updateEvent);
        setGetEventData(updateEvent.data);
      } catch (err) {
        console.log("event fetch failed:", err);
      }
    };
    fetchEventdata();
  }, []);
  return (
    <>
      <EventContext.Provider value={getEventData}>
        {children}
      </EventContext.Provider>
    </>
  );
};
// event context  code end

// forsale context  code start
const ProductContext = createContext();
export const useProduct = () => useContext(ProductContext);

export const ProductProvider = ({ children }) => {
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const updateProductData = await getProduct();
        console.log("hi", updateProductData);
        setProductData(updateProductData.data);
      } catch (err) {
        console.log("saledata fetched failed:", err);
      }
    };
    fetchProductData();
  }, []);
  return (
    <>
      <ProductContext.Provider value={productData}>
        {children}
      </ProductContext.Provider>
    </>
  );
};
// forsale context  code end
