import React from "react";
import { Banner } from "../Banner/Banner";
import { Slider } from "../ImgSlider/ImgSlider";
import { Event } from "../Events/Event";
import { Gallery } from "../Gallery/Gallery";
import { Footer } from "../Footer/Footer";

export const Home = () => {
  return (
    <>
      <Banner />
      <Slider />
      <Event />
      <Gallery />
      <Footer />
    </>
  );
};
