import React, { useEffect, useState } from "react";
import "./InnerEvent.css";
import { Header } from "../../Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import { BiCalendar } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useEvent } from "../../../srcApi/ContexApit";
import parse from "html-react-parser";
import { Footer } from "../../Footer/Footer";

export const InnerEvent = () => {
  const getEventData = useEvent();
  const imagepath = "https://artnbgurung.com/uploads/image/";

  return (
    <>
      <div className="inner-header">
        <Header />
      </div>
      <section className="section-padding event-section">
        <Container>
          {getEventData?.map((event, index) => (
            <div key={index} className="event-container">
              <div className="cl-header w-50">
                <h2 className="title">{event.meta_keyword}</h2>
                <p>
                  Torem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus, amet,
                  consectetur adipiscing elit.
                </p>
              </div>

              <div className="event-list">
                <Row>
                  <Col
                    sm={{ span: 5 }}
                    className={index % 2 === 0 ? "col-img" : "col-content"}
                  >
                    <div className="event-img-wrap">
                      <img src={imagepath + event.image} alt="eventlist" />
                    </div>
                  </Col>
                  <Col
                    sm={{ span: 7 }}
                    className={index % 2 === 0 ? "col-content" : "col-img"}
                  >
                    <div className="event-content">
                      <div className="event-date">
                        <BiCalendar className="icon" />
                        <span>{event.published_at}</span>
                      </div>
                      <div className="cl-header">
                        <h2 className="title">{event.title}</h2>
                      </div>
                      <div className="content">
                        <p>{parse(event.description)}</p>
                        <Link to="/eventdetail" className="more-btn">
                          View Detail
                          <i className="icon fa-solid fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* <div className="event-list">
              <Row>
                <Col sm={{ span: 5 }} className="col-img">
                  <div className="event-img-wrap">
                    <img src="images/event/eventlist1.png" alt="eventlist1" />
                  </div>
                </Col>
                <Col sm={{ span: 7 }} className="col-content">
                  <div className="event-content">
                    <div className="event-date">
                      <BiCalendar className="icon" />
                      <span>12/02/2023</span>
                    </div>
                    <div className="cl-header">
                      <h2 className="title">Event 2</h2>
                    </div>
                    <div className="content">
                      <p>
                        Born in 1976 in Lekhnath in the western municipality of
                        Nepal, NB Gurung is becoming globally known as a
                        passionate leading watercolour artist. His artistic
                        passion began as a young child when he started copying
                        images from school text books. Encouraged by classmates
                        and his teachers, he began to dream of being an artist
                        some day, however after completing his School Leaving
                        Certificate (SLC), his desire to become formally trained
                        in art had to wait another five years
                      </p>
                      <Link to="/eventdetail" className="more-btn">
                        View Detail{" "}
                        <i className="icon fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="event-list">
              <Row>
                <Col sm={{ span: 5 }}>
                  <div className="event-img-wrap">
                    <img src="images/event/eventlist2.png" alt="eventlist2" />
                  </div>
                </Col>
                <Col sm={{ span: 7 }}>
                  <div className="event-content">
                    <div className="event-date">
                      <BiCalendar className="icon" />
                      <span>12/02/2023</span>
                    </div>
                    <div className="cl-header">
                      <h2 className="title">Event 2</h2>
                    </div>
                    <div className="content">
                      <p>
                        Born in 1976 in Lekhnath in the western municipality of
                        Nepal, NB Gurung is becoming globally known as a
                        passionate leading watercolour artist. His artistic
                        passion began as a young child when he started copying
                        images from school text books. Encouraged by classmates
                        and his teachers, he began to dream of being an artist
                        some day, however after completing his School Leaving
                        Certificate (SLC), his desire to become formally trained
                        in art had to wait another five years
                      </p>
                      <Link to="/eventdetail" className="more-btn">
                        View Detail{" "}
                        <i className="icon fa-solid fa-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </div> */}
            </div>
          ))}
        </Container>
      </section>
      <Footer />
    </>
  );
};
