import React, { useEffect, useState } from "react";
import "./InnerAbout.css";
import { Col, Container, Row } from "react-bootstrap";
import { Footer } from "../../Footer/Footer";
import { Header } from "../../Header/Header";
import { Link } from "react-router-dom";
import {
  BiLogoFacebookCircle,
  BiLogoLinkedin,
  BiLogoTwitter,
} from "react-icons/bi";
import { getAbout } from "../../../srcApi/Api";
import parse from "html-react-parser";
import { Loader } from "../../Loarder";

export const InnerAbout = () => {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const updateAbout = await getAbout();
        setAboutData(updateAbout?.data.blocks);
        // setAboutData(updateAbout);
        console.log("hi about:", updateAbout);
      } catch (err) {
        console.log("Error fetching data:", err);
      }
    };
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    fetchAboutData();
  }, []);
  const imagepath = "https://artnbgurung.com/uploads/image/";

  return (
    <>
      <div className="inner-header ">
        <Header />
      </div>
      <section className="innerabout-section section-padding">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loader className="text-center" />
          </div>
        ) : (
          aboutData && (
            <Container>
              <Row>
                <Col md={6}>
                  <div className="left-content about-me">
                    <div className="cl-header text-white">
                      <h2 className="title">
                        {parse(aboutData["banner-block"]?.title)}
                      </h2>
                    </div>
                    <p>{parse(aboutData["banner-block"]?.content)}</p>
                    <ul className="social-icon">
                      <li>
                        <Link to="" className="fb">
                          <BiLogoFacebookCircle className="icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="" className="tw">
                          <BiLogoTwitter className="icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="" className="ln">
                          <BiLogoLinkedin className="icon" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="img-wrap">
                    <img
                      src={imagepath + aboutData["banner-block"]?.image}
                      alt={aboutData["banner-block"]?.image}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          )
        )}
      </section>

      <section className="section-padding about-paragraph">
        {loading ? (
          <div className="d-flex justify-content-center">
            <Loader className="text-center" />
          </div>
        ) : (
          aboutData && (
            <Container>
              <div className="para-wrap">
                <p>{parse(aboutData["block-1"]?.content)}</p>
                <div className="list">
                  <h6 className="title">{aboutData["block-3"]?.title}</h6>
                  <div>{parse(aboutData["block-3"].content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">{aboutData.university.title}</h6>
                  <div>{parse(aboutData.university.content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">
                    {aboutData["solo-exhibition"].title}
                  </h6>
                  <div>{parse(aboutData["solo-exhibition"].content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">
                    {parse(aboutData["group-exhibition"].title)}
                  </h6>
                  <div>{parse(aboutData["group-exhibition"].content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">{aboutData.workshop.title}</h6>
                  <div>{parse(aboutData.workshop.content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">{aboutData.awards.title}</h6>
                  <div>{parse(aboutData.awards.content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">
                    {aboutData["associated-with"].title}
                  </h6>
                  <div>{parse(aboutData["associated-with"].content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">{aboutData.collection.title}</h6>
                  <div>{parse(aboutData.collection.content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">
                    {aboutData["international-visit"].title}
                  </h6>
                  <div>{parse(aboutData["international-visit"].content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">{aboutData["early-success"].title}</h6>
                  <div>{parse(aboutData["early-success"].content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">
                    {aboutData["freelance-artist"].title}
                  </h6>
                  <div>{parse(aboutData["freelance-artist"].content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">{aboutData.recognition.title}</h6>
                  <div>{parse(aboutData.recognition.content)}</div>
                </div>
                <div className="list">
                  <h6 className="title">
                    {aboutData["nepalese-art-societies"].title}
                  </h6>
                  <div>
                    {parse(aboutData["nepalese-art-societies"].content)}
                  </div>
                </div>
              </div>
              <div className="image-collection">
                <Row>
                  <Col md={3}>
                    <div className="img-wrap">
                      <img src="Images/frame1.png" alt="frame1" />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="img-wrap">
                      <img src="Images/Frame.png" alt="frame" />
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="img-wrap">
                      <img src="Images/frame2.png" alt="frame2" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          )
        )}
      </section>
      <Footer />
    </>
  );
};
