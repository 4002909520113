import React from "react";
import "./Event.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useEvent } from "../../srcApi/ContexApit";

export const Event = () => {
  const getEventData = useEvent();
  const imagepath = "https://artnbgurung.com/uploads/image/";

  return (
    <>
      <section className="section-padding event-section">
        <Container>
          {getEventData?.map((event, index) => (
            <Row key={index} className="align-items-center">
              <Col sm={6}>
                <div className="event-wrap">
                  <div className="cl-header">
                    <h2 className="title">{event.meta_keyword}</h2>
                  </div>
                  <div className="content">
                    {event.title}
                    <div className="more-btn">{parse(event.description)}</div>
                    <Link to="/event" className="cl-button">
                      See All Events
                      <i className="icon fa-solid fa-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </Col>
              <Col lg={{ span: 5, offset: 1 }} sm={{ span: 6 }}>
                <div className="event-img">
                  <img
                    src={imagepath + event.image}
                    alt={`Event Image ${index}`}
                  />
                </div>
              </Col>
            </Row>
          ))}
        </Container>
      </section>
    </>
  );
};
