import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAbout, getFooter } from "../../srcApi/Api";
import parse from "html-react-parser";

export const Footer = () => {
  const [footerData, setFooterData] = useState();
  useEffect(() => {
    const fetchFooterData = async () => {
      try {
        const updatefooter = await getFooter();
        console.log("footer data sucess:", updatefooter);
        const filteredData = updatefooter.data.filter(
          (item) => item.slug === "email" || item.slug === "contact"
        );

        setFooterData(filteredData);
      } catch (err) {
        console.log("footer data failed:", err);
      }
    };
    fetchFooterData();
  }, []);
  const imagepath = "https://artnbgurung.com/assets/images/logo.png";
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const updateAbout = await getAbout();
        setAboutData(updateAbout?.data.blocks);
        // setAboutData(updateAbout);
        console.log("hi:", updateAbout);
      } catch (err) {
        console.log("Error fetching data:", err);
      }
    };
    fetchAboutData();
  }, []);
  return (
    <>
      <footer className="footer">
        <div className="cl-footer">
          <Container>
            <Row className="align-items-center">
              {aboutData && (
                <Col md={5}>
                  <div className="footer-left-wrap">
                    <h2 className="title">{aboutData["banner-block"].title}</h2>
                    <p className="">
                      {parse(
                        aboutData["banner-block"].content
                          .split(/\.|\?|!/)
                          .filter((sentence) => sentence.trim() !== "") // Remove empty lines
                          .slice(0, 2) // Take the first three lines)
                          .join(".")
                      )}
                    </p>
                    <Link to="/about" className="cl-button works-butn">
                      <i className="icon fa fa-plus"></i>Read more
                    </Link>
                  </div>
                </Col>
              )}
              <Col lg={{ span: 6, offset: 1 }} md={{ span: 7 }}>
                <div className="footer-right-wrap">
                  <ul className="contact-info">
                    {footerData?.map((footerdata, index) => (
                      <li key={index}>
                        {footerdata.slug === "email" && (
                          <>
                            <i className="icon fa-regular fa-envelope"></i>
                            <span>{footerdata.slug}</span>
                            <Link to="mailto:nbgurung@gmail.com">
                              {footerdata.value}
                            </Link>
                          </>
                        )}
                        {footerdata.slug === "contact" && (
                          <>
                            <i class="icon fa-solid fa-phone-volume"></i>
                            <span>{footerdata.slug}</span>
                            <Link to="tel:9841834990">{footerdata.value}</Link>
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="footer-bottom">
          <Container>
            <div className="d-flex align-items-center">
              <div className="img-wrap">
                <Link to="/">
                  <img src="/Images/logo-1.png" />
                </Link>
              </div>
              <div className="ms-auto">
                <p>
                  © 2023 by
                  <Link to="https://intopros.com/" target="__blank">
                    {" "}
                    Intopros Technologies
                  </Link>
                </p>
              </div>
            </div>
          </Container>
        </div>
      </footer>
    </>
  );
};
